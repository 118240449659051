define("discourse/plugins/discourse-epic-games/discourse/connectors/user-post-names/user-profile-badges", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/ue-badges", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _ueBadges, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserProfileBadges = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <UeBadges
      @badges={{@outletArgs.model.custom_fields.badges}}
      @teamMemberships={{@outletArgs.model.custom_fields.team_memberships}}
    />
  
  */
  {
    "id": "oe2ELKLy",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@badges\",\"@teamMemberships\"],[[30,1,[\"model\",\"custom_fields\",\"badges\"]],[30,1,[\"model\",\"custom_fields\",\"team_memberships\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/user-post-names/user-profile-badges.js",
    "scope": () => [_ueBadges.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "user-profile-badges:UserProfileBadges"));
  var _default = _exports.default = UserProfileBadges;
});
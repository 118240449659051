define("discourse/plugins/discourse-epic-games/discourse/components/dsa-report-user", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-epic-games/discourse/lib/dsa-report-url", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _dsaReportUrl, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DsaReportUser extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    navigateToReportUrl() {
      const reportUrl = (0, _dsaReportUrl.default)({
        contentUrl: `/user-by-id/${this.args.user.id}`,
        contentType: "discourse_profile",
        dsaReportUrl: "https://safety.epicgames.com/policies/reporting-misconduct/submit-report"
      });
      window.open(reportUrl, "_blank");
    }
    static #_2 = (() => dt7948.n(this.prototype, "navigateToReportUrl", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon="flag"
          @action={{this.navigateToReportUrl}}
          @label="discourse_epic_games.dsa_report.report_user"
          class="btn-default epic-report-user-btn"
        />
      
    */
    {
      "id": "CvCUjTv7",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"btn-default epic-report-user-btn\"]],[[\"@icon\",\"@action\",\"@label\"],[\"flag\",[30,0,[\"navigateToReportUrl\"]],\"discourse_epic_games.dsa_report.report_user\"]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/dsa-report-user.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DsaReportUser;
});
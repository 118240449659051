define("discourse/plugins/discourse-epic-games/discourse/initializers/dsa-report", ["exports", "@ember/helper", "@ember/object", "discourse/components/d-button", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent", "discourse/plugins/discourse-epic-games/discourse/lib/dsa-report-url", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _object, _dButton, _pluginApi, _constants, _digitalConsent, _dsaReportUrl, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const isPrivateTopic = topic => {
    return topic.archetype === "private_message" || topic.category?.read_restricted;
  };
  const openReportUrl = postId => {
    const reportUrl = (0, _dsaReportUrl.default)({
      contentUrl: `/p/${postId}`,
      contentType: "discourse_post",
      dsaReportUrl: "https://safety.epicgames.com/policies/reporting-misconduct/submit-report"
    });
    window.open(reportUrl, "_blank");
  };
  const DsaFlagButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DButton
      class="dsa-report-post"
      ...attributes
      @action={{fn openReportUrl @post.id}}
      @icon="flag"
      @title="discourse_epic_games.dsa_report.report_post"
    />
  
  */
  {
    "id": "JD2IKKkC",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"dsa-report-post\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[28,[32,1],[[32,2],[30,2,[\"id\"]]],null],\"flag\",\"discourse_epic_games.dsa_report.report_post\"]],null],[1,\"\\n\"]],[\"&attrs\",\"@post\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/dsa-report.js",
    "scope": () => [_dButton.default, _helper.fn, openReportUrl],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "dsa-report:DsaFlagButton"));
  var _default = _exports.default = {
    name: "epic-dsa-report",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      const site = container.lookup("service:site");
      const tooltip = container.lookup("service:tooltip");
      document.body.classList.add("dsa-report-enabled");
      (0, _pluginApi.withPluginApi)("1.35.0", api => {
        api.registerTopicFooterButton({
          id: "dsa-report-topic",
          action() {
            const reportUrl = (0, _dsaReportUrl.default)({
              contentUrl: `/t/${this.topic.id}`,
              contentType: "discourse_topic",
              dsaReportUrl: "https://safety.epicgames.com/policies/reporting-misconduct/submit-report"
            });
            window.open(reportUrl, "_blank");
          },
          icon: "flag",
          label: "discourse_epic_games.dsa_report.report_topic",
          anonymousOnly: true
        });
        api.registerValueTransformer("post-menu-buttons", _ref => {
          let {
            value: dag,
            context: {
              post
            }
          } = _ref;
          if (post.canFlag || currentUser?.staff ||
          // don't show dsa-report flag button for system users
          post.user_id <= 0) {
            return;
          }
          // If flagging is disabled, shows a direct link to the external report form
          // for non system user posts
          if (post.user_id > 0) {
            dag.add("discourse-epic-games-dsa-flag", DsaFlagButton);
          }
        });
        api.modifyClass("component:modal/flag", Superclass => class extends Superclass {
          get isDiscourseOnlyAuthor() {
            return _constants.DISCOURSE_SYSTEM_USERS.includes(this.args.model.flagModel.username);
          }
          get submitEnabled() {
            if (!this.selected) {
              return super.submitEnabled;
            }
            if (!this.isDiscourseOnlyAuthor && _constants.DSA_FLAG_TYPES.includes(this.selected?.name_key)) {
              return true;
            }
            if (!this.selected?.is_custom_flag) {
              return true;
            }
            super.submitEnabled;
          }
          changePostActionType(actionType) {
            if (!this.isDiscourseOnlyAuthor && _constants.DSA_FLAG_TYPES.includes(actionType?.name_key)) {
              actionType.set("is_custom_flag", false);
            }
            super.changePostActionType(actionType);
          }
          static #_ = (() => dt7948.n(this.prototype, "changePostActionType", [_object.action]))();
          createFlag(opts) {
            // opts is only passed for staff actions
            const isSystemTopic = this.args.model?.flagModel?._details?.created_by?.id === -1;
            if (opts || !_constants.DSA_FLAG_TYPES.includes(this.selected?.name_key) || this.isDiscourseOnlyAuthor || isSystemTopic) {
              if (this.selected.is_custom_flag) {
                opts = {
                  ...opts,
                  message: this.message
                };
              }
              // don't let the user complete creating a discourse flag on the post if they are in DIGITAL_CONSENT_PENDING_GROUP
              if ((0, _digitalConsent.default)(currentUser, tooltip, site, "create_flag")) {
                return;
              }
              return this.args.model.flagTarget.create(this, opts);
            }
            const model = this.args.model.flagModel;
            let contentUrl, contentType;
            if (this.args.model.flagTarget.targetsTopic()) {
              contentUrl = `/t/${model.id}`;
              const isPrivate = isPrivateTopic(model);
              contentType = isPrivate ? "discourse_topic_private" : "discourse_topic";
            } else {
              contentUrl = `/p/${model.id}`;
              const isPrivate = isPrivateTopic(model.topic);
              contentType = isPrivate ? "discourse_post_private" : "discourse_post";
            }
            const reportUrl = (0, _dsaReportUrl.default)({
              contentUrl,
              contentType,
              dsaReportUrl: "https://safety.epicgames.com/policies/reporting-misconduct/submit-report"
            });
            window.open(reportUrl, "_blank");
          }
          static #_2 = (() => dt7948.n(this.prototype, "createFlag", [_object.action]))();
        });
      });
    }
  };
});
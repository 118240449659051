define("discourse/plugins/discourse-epic-games/discourse/components/special-profile-classes", ["exports", "@glimmer/component", "@ember/helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SpecialProfileClasses extends _component.default {
    get badges() {
      return this.args.user?.unreal_badges || this.args.user?.custom_fields?.badges || undefined;
    }
    get userBadgeIds() {
      if (!this.badges || typeof this.badges !== "string") {
        return "";
      }
      return this.badges.split(",").map(e => parseInt(e, 10));
    }
    get userHighlightClass() {
      // always return staff badge or hero badge first
      if (this.userBadgeIds.includes(1)) {
        return "staff";
      } else if (this.userBadgeIds.includes(0)) {
        return "hero";
      } else if (this.userBadgeIds.includes(2)) {
        return "contributor";
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.userHighlightClass}}
          <div
            class={{if
              this.userHighlightClass
              (concat "unreal-highlight-" this.userHighlightClass)
            }}
          ></div>
        {{/if}}
      
    */
    {
      "id": "cciU5fXy",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"userHighlightClass\"]],[[[1,\"      \"],[10,0],[15,0,[52,[30,0,[\"userHighlightClass\"]],[28,[32,0],[\"unreal-highlight-\",[30,0,[\"userHighlightClass\"]]],null]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/special-profile-classes.js",
      "scope": () => [_helper.concat],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SpecialProfileClasses;
});
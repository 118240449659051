define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-list-before-status/type-icon", ["exports", "discourse-common/helpers/d-icon", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const GlobalFilterIcons = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <span class="custom-topic-type">
      {{dIcon "gf-unreal-engine"}}
      {{dIcon "gf-fortnite"}}
      {{dIcon "gf-epic-games-store"}}
      {{dIcon "gf-capturing-reality"}}
      {{dIcon "ue-question"}}
      {{dIcon "ue-discussion"}}
      {{dIcon "gf-metahuman"}}
      {{dIcon "gf-twinmotion"}}
      {{dIcon "gf-fab"}}
      {{dIcon "gf-epic-for-indies"}}
    </span>
  
  */
  {
    "id": "Hijs5lbE",
    "block": "[[[1,\"\\n  \"],[10,1],[14,0,\"custom-topic-type\"],[12],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-unreal-engine\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-fortnite\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-epic-games-store\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-capturing-reality\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"ue-question\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"ue-discussion\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-metahuman\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-twinmotion\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-fab\"],null]],[1,\"\\n    \"],[1,[28,[32,0],[\"gf-epic-for-indies\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-list-before-status/type-icon.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "type-icon:GlobalFilterIcons"));
  var _default = _exports.default = GlobalFilterIcons;
});
define("discourse/plugins/discourse-epic-games/discourse/connectors/discovery-list-container-top/subcategory-list-buttons-wrapper", ["exports", "discourse/plugins/discourse-epic-games/discourse/components/subcategory-list-buttons", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _subcategoryListButtons, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubcategoryListButtonsWrapper = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <SubcategoryListButtons
      @subcategories={{@outletArgs.category.subcategory_list}}
    />
  
  */
  {
    "id": "o2Enc8dA",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@subcategories\"],[[30,1,[\"category\",\"subcategory_list\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/discovery-list-container-top/subcategory-list-buttons-wrapper.js",
    "scope": () => [_subcategoryListButtons.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "subcategory-list-buttons-wrapper:SubcategoryListButtonsWrapper"));
  var _default = _exports.default = SubcategoryListButtonsWrapper;
});
define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar", ["exports", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicStatusBar = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      class="custom-topic-status-bar
        {{if @outletArgs.model.accepted_answer 'resolved'}}"
    >
      <span>
        {{i18n "discourse_epic_games.topic_status.qa_topic"}}
        <span>|</span>
        {{#if @outletArgs.model.accepted_answer}}
          {{i18n "discourse_epic_games.topic_status.status_badge.solved"}}
        {{else}}
          {{i18n "discourse_epic_games.topic_status.status_badge.unsolved"}}
        {{/if}}
      </span>
    </div>
  
  */
  {
    "id": "rccku1KD",
    "block": "[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"custom-topic-status-bar\\n      \",[52,[30,1,[\"model\",\"accepted_answer\"]],\"resolved\"]]]],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.qa_topic\"],null]],[1,\"\\n      \"],[10,1],[12],[1,\"|\"],[13],[1,\"\\n\"],[41,[30,1,[\"model\",\"accepted_answer\"]],[[[1,\"        \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.solved\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.unsolved\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar.js",
    "scope": () => [_i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-status-bar:TopicStatusBar"));
  var _default = _exports.default = TopicStatusBar;
});
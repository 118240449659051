define("discourse/plugins/discourse-epic-games/discourse/connectors/composer-after-composer-editor/legal-notice", ["exports", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LegalNotice = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="unreal-composer-legal-notice">
      <p>
        {{i18n "discourse_epic_games.legal_notice.content"}}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={{i18n "discourse_epic_games.legal_notice.link.url"}}
        >
          {{i18n "discourse_epic_games.legal_notice.link.text"}}
        </a>
      </p>
    </div>
  
  */
  {
    "id": "4fV5u/2+",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"unreal-composer-legal-notice\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_epic_games.legal_notice.content\"],null]],[1,\"\\n      \"],[10,3],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,6,[28,[32,0],[\"discourse_epic_games.legal_notice.link.url\"],null]],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_epic_games.legal_notice.link.text\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/composer-after-composer-editor/legal-notice.js",
    "scope": () => [_i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "legal-notice:LegalNotice"));
  var _default = _exports.default = LegalNotice;
});
define("discourse/plugins/discourse-epic-games/discourse/initializers/digital-consent-guards", ["exports", "@ember/object", "discourse/lib/plugin-api", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent"], function (_exports, _object, _pluginApi, _digitalConsent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-digital-consent-guards",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!(0, _digitalConsent.hasDigitalConsentPendingGroup)(currentUser)) {
        return;
      }
      const router = container.lookup("service:router");
      const site = container.lookup("service:site");
      const tooltip = container.lookup("service:tooltip");
      document.body.classList.add("consent-to-communicate-pending");
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("controller:topic", Superclass => class extends Superclass {
          replyToPost() {
            // don't let the user reply if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "reply_to_post")) {
              return;
            }
            super.replyToPost(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "replyToPost", [_object.action]))();
          replyAsNewTopic() {
            // don't let the user reply if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "invite_topic")) {
              return;
            }
            super.replyAsNewTopic(...arguments);
          }
          static #_2 = (() => dt7948.n(this.prototype, "replyAsNewTopic", [_object.action]))();
        });
        api.modifyClass("component:bookmark-menu", Superclass => class extends Superclass {
          onShowMenu() {
            // don't let the user create a bookmark if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "bookmark_menu")) {
              return false;
            }
            super.onShowMenu(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "onShowMenu", [_object.action]))();
        });
        api.modifyClass("component:modal/share-topic", Superclass => class extends Superclass {
          inviteUsers() {
            // don't let the user create an invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "invite_topic")) {
              return false;
            }
            super.inviteUsers(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "inviteUsers", [_object.action]))();
        });
        api.modifyClass("component:invite-panel", Superclass => class extends Superclass {
          createInvite() {
            // don't let the user create a invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "invite_profile")) {
              return false;
            }
            super.createInvite(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createInvite", [_object.action]))();
        });
        api.modifyClass("controller:user-invited-show", Superclass => class extends Superclass {
          createInvite() {
            // don't let the user create an invite if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "invite_profile")) {
              return false;
            }
            super.createInvite(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "createInvite", [_object.action]))();
        });
        api.reopenWidget("post-menu", {
          like() {
            // don't let the user like a post if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "like")) {
              return false;
            }
            this._super(...arguments);
          }
        });
        api.registerBehaviorTransformer("topic-vote-button-click", _ref => {
          let {
            next
          } = _ref;
          // don't let the user vote if they are in DIGITAL_CONSENT_PENDING_GROUP
          if ((0, _digitalConsent.default)(currentUser, tooltip, site, "vote")) {
            return false;
          }
          next();
        });
        api.modifyClass("route:application", Superclass => class extends Superclass {
          composePrivateMessage() {
            // don't let the user create a private message if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "private_message")) {
              return false;
            }
            super.composePrivateMessage(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "composePrivateMessage", [_object.action]))();
        });
        api.modifyClass("controller:preferences/profile", Superclass => class extends Superclass {
          showFeaturedTopicModal() {
            // don't let the user update their featured topic if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "featured_topic")) {
              return false;
            }
            super.showFeaturedTopicModal(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "showFeaturedTopicModal", [_object.action]))();
        });
        api.modifyClass("component:post-text-selection", Superclass => class extends Superclass {
          insertQuote() {
            // don't let the user quote from quick menu if they are in DIGITAL_CONSENT_PENDING_GROUP
            if ((0, _digitalConsent.default)(currentUser, tooltip, site, "quote")) {
              return false;
            }
            super.insertQuote(...arguments);
          }
          static #_ = (() => dt7948.n(this.prototype, "insertQuote", [_object.action]))();
        });
        router.on("routeWillChange", transition => {
          // don't let the user create a new topic / message if they are in DIGITAL_CONSENT_PENDING_GROUP
          if ((transition.targetName === "new-topic" || transition.targetName === "new-message") && (0, _digitalConsent.hasDigitalConsentPendingGroup)(currentUser)) {
            return router.transitionTo("/404");
          }
        });
      });
    }
  };
});
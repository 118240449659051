define("discourse/plugins/discourse-epic-games/discourse/initializers/topic-list-columns", ["exports", "discourse/components/topic-list/item/posters-cell", "discourse/helpers/avatar", "discourse/helpers/concat-class", "discourse/lib/plugin-api", "discourse-i18n", "discourse/plugins/discourse-epic-games/discourse/helpers/epic-avatar-highlight", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _postersCell, _avatar, _concatClass, _pluginApi, _discourseI18n, _epicAvatarHighlight, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EpicItemPostersCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="posters topic-list-data">
      {{#each @topic.featuredUsers as |poster|}}
        {{#if poster.moreCount}}
          <a class="posters-more-count">{{poster.moreCount}}</a>
        {{else}}
          <a
            href={{poster.user.path}}
            class={{concatClass
              (epicAvatarHighlight poster.user)
              poster.extraClasses
            }}
          >
            {{avatar
              poster
              avatarTemplatePath="user.avatar_template"
              usernamePath="user.username"
              namePath="user.name"
              imageSize="small"
            }}
          </a>
        {{/if}}
      {{/each}}
    </td>
  
  */
  {
    "id": "ye3DODHN",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"posters topic-list-data\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"featuredUsers\"]]],null]],null],null,[[[41,[30,2,[\"moreCount\"]],[[[1,\"        \"],[10,3],[14,0,\"posters-more-count\"],[12],[1,[30,2,[\"moreCount\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[15,6,[30,2,[\"user\",\"path\"]]],[15,0,[28,[32,0],[[28,[32,1],[[30,2,[\"user\"]]],null],[30,2,[\"extraClasses\"]]],null]],[12],[1,\"\\n          \"],[1,[28,[32,2],[[30,2]],[[\"avatarTemplatePath\",\"usernamePath\",\"namePath\",\"imageSize\"],[\"user.avatar_template\",\"user.username\",\"user.name\",\"small\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@topic\",\"poster\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/topic-list-columns.js",
    "scope": () => [_concatClass.default, _epicAvatarHighlight.default, _avatar.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-list-columns:EpicItemPostersCell"));
  const EpicHeaderVoteCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <th class="vote-count topic-list-data num">{{i18n
        "discourse_epic_games.topic_list.votes"
      }}</th>
  
  */
  {
    "id": "+Xqr1Kru",
    "block": "[[[1,\"\\n  \"],[10,\"th\"],[14,0,\"vote-count topic-list-data num\"],[12],[1,[28,[32,0],[\"discourse_epic_games.topic_list.votes\"],null]],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/topic-list-columns.js",
    "scope": () => [_discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-list-columns:EpicHeaderVoteCell"));
  const EpicItemVoteCell = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="vote-count num topic-list-data">
      {{@topic.vote_count}}/10
    </td>
  
  */
  {
    "id": "rrWlZNN2",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"vote-count num topic-list-data\"],[12],[1,\"\\n    \"],[1,[30,1,[\"vote_count\"]]],[1,\"/10\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/initializers/topic-list-columns.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-list-columns:EpicItemVoteCell"));
  var _default = _exports.default = {
    name: "epic-modify-topic-list",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        api.registerValueTransformer("topic-list-columns", _ref => {
          let {
            value: columns
          } = _ref;
          columns.add("votes", {
            header: EpicHeaderVoteCell,
            item: EpicItemVoteCell
          });
          columns.replace("posters", {
            item: EpicItemPostersCell
          }, {
            item: _postersCell.default
          });
          return columns;
        });
      });
    }
  };
});
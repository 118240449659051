define("discourse/plugins/discourse-epic-games/discourse/initializers/clickable-topic-list-item", ["exports", "@ember/object", "discourse/lib/intercept-click", "discourse/lib/plugin-api"], function (_exports, _object, _interceptClick, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "clickable-topic-list-item",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.14", api => {
        api.modifyClass("component:topic-list/item", Superclass => class extends Superclass {
          click(e) {
            // make full row click target
            if (e.target.tagName === "TD" || e.target.classList.contains("link-bottom-line") || e.target.classList.contains("link-top-line")) {
              if ((0, _interceptClick.wantsNewWindow)(e)) {
                return true;
              }
              return this.navigateToTopic(this.args.topic, this.args.topic.lastUnreadUrl);
            }
            super.click(e);
          }
          static #_ = (() => dt7948.n(this.prototype, "click", [_object.action]))();
        });
      });
    }
  };
});
define("discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-controls/dsa-report-user-button", ["exports", "truth-helpers", "discourse/plugins/discourse-epic-games/discourse/components/dsa-report-user", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _truthHelpers, _dsaReportUser, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DsaReportUserButton = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{! Add a button to report a user in the user profile page for non system users }}
    {{#if (gt @outletArgs.model.id 0)}}
      <DsaReportUser @user={{@outletArgs.model}} />
    {{/if}}
  
  */
  {
    "id": "HW2OooVY",
    "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1,[\"model\",\"id\"]],0],null],[[[1,\"    \"],[8,[32,1],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/user-profile-controls/dsa-report-user-button.js",
    "scope": () => [_truthHelpers.gt, _dsaReportUser.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "dsa-report-user-button:DsaReportUserButton"));
  var _default = _exports.default = DsaReportUserButton;
});